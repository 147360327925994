import { submitContactForm } from "src/services";
import {
  callicon,
  ditinusMOV,
  facebooklogo,
  instagramlogo,
  linkedinlogo,
  locationicon,
  logox,
  mailicon,
  questionicon,
  sign,
  whatsapplogo,
} from "../../assets/index";
import Recaptcha from "../../Components/Recaptcha";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const Index = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    services: "",
    comment: "",
  });

  useEffect(() => {
    const handleClick = (event) => {
      const targetLink = event.target.closest('a[href="#contact-form"]');

      if (targetLink) {
        const target = document.querySelector("#contact-form");

        if (target) {
          target.scrollIntoView({ behavior: "smooth" });

        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);



  const [token, setToken] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (event) => {
    if (errors) {
      setErrors({});
    }
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setToken(value);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!token) newErrors.verify = "Please Verify.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be digits only.";
    }
    if (!formData.services) newErrors.services = "Please select a service.";
    if (!formData.comment)
      newErrors.comment = "Additional comments are required.";

    return newErrors;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // await Swal.fire({
      //   title: "Error!",
      //   text: "Please fix the validation errors.",
      //   icon: "error",
      //   confirmButtonText: "OK",
      // });
    } else {
      setLoading(true);
      setErrors({});
      try {
        const response: any = await submitContactForm(formData);
        if (response) {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            phone: "",
            services: "",
            comment: "",
          });
          setToken("");
          await Swal.fire({
            title: "Success!",
            text: "Your form has been submitted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        setLoading(false);
        await Swal.fire({
          title: "Error!",
          text: "There was an issue submitting your form. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const location = useLocation();  // Access current location object

  // Reset errors whenever the route changes
  useEffect(() => {
    setErrors({});
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>
          Contact Us | Ditinus
          Technology
        </title>
      </Helmet>
      <main>

        <section className="subBanner2 " style={{ marginTop: "80px" }}>
          <div className="container mt-md-5 ">
            <div className="row justify-content-center align-items-center ">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-5">

                <h1 className="heading-222 fw-normal mt-2" >

                  We’d Love to Hear From You!</h1>
                <h1 className="heading-0002  ">

                  Get Personalized Assistance and Expert IT Solutions Designed Just for You!</h1>
                <p className="p-text18 mt-3">
                  Have questions, need expert consultation, or are curious about how we can help achieve your business goals?
                  <br />
                  Reach out to us – we’re just one click away!

                </p>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-5 mb-5 ">
                <a
                  href="#contact-form"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-info">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12 col-12 col-sm-12 tablet-view ">
                <img loading="lazy" src={sign} alt="img" />
              </div>
              <div className="col-xl-1 col-lg-1">

              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pt-4">
                <h2 className="text-white p-text18">Have questions or inquiries?</h2>
                <h4 className="heading-0003 text-white">
                  Reach out to us for personalized <br />
                  <span className="text-green">assistance and support.</span>
                </h4>
                <h2 className="text-white heading-0004">
                  We’re here to help you every step of the way.
                </h2>
                <h5 className="heading-0005 text-white">Ditinus Technology,</h5>
                <h5 className="heading-0006 text-white">
                  E:301, Vista Infotech Industrial Area, Sector 75, Sahibzada Ajit Singh Nagar, Punjab 160055
                </h5>
                <div className="media-icon d-flex ">
                  <Link to="https://www.facebook.com/ditinus/"><img loading="lazy" src={facebooklogo} alt="facebook logo" /></Link>
                  <Link to="https://www.instagram.com/ditinus/"><img loading="lazy" src={instagramlogo} alt="instagram logo" /></Link>
                  <Link to="https://www.linkedin.com/company/ditinus-technology-pvt-ltd"> <img loading="lazy" src={linkedinlogo} alt="linkedin logo" /></Link>
                  <Link to="https://wa.me/+919915578189"> <img loading="lazy" src={whatsapplogo} alt="whatsapp logo" /></Link>
                  <Link to="https://x.com/Ditinusmohali"><img loading="lazy" src={logox} alt="x logo" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contactForm-section" id="contact-form">
          <div className="container">
            <div className="row g-md-5 g-sm-5 g-5 g-xl-5 g-lg-3">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <form
                  className="contact-form col-inner-box1"
                  onSubmit={handleFormSubmit}
                >
                  <div className="col-12 text-center mb-4">
                    <h4 className="heading-04">Let’s Talk</h4>
                    <p className="p-text2 text-white fw-normal" >Get more done, faster-together</p>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="formInput  m-0"
                      value={formData.name}
                      onChange={onChange}
                    />
                    <div >

                    </div>
                    <div className="error-container-contact p-0 m-0">

                      {errors.name && (
                        <span className="error-contact-form">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      className="formInput  m-0"
                      value={formData.email}
                      onChange={onChange}
                    />
                    <div className="error-container-contact p-0 m-0">

                      {errors.email && (
                        <span className="error-contact-form">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      className="formInput  m-0"
                      value={formData.phone}
                      onChange={onChange}
                      maxLength={13}
                      minLength={9}
                    />
                    <div className="error-container-contact p-0 m-0">

                      {errors.phone && (
                        <span className="error-contact-form">{errors.phone}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <select
                      name="services"
                      className="formInput m-0 selectInput pe-5"
                      value={formData.services}
                      onChange={onChange}
                    >
                      <option value="">Select Services</option>
                      <option value="Design">Design</option>
                      <option value="Development">Development</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                    </select>
                    <div className="error-container-contact p-0 m-0">

                      {errors.services && (
                        <span className="error-contact-form">{errors.services}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <textarea
                      placeholder="Message"
                      className="formInput  m-0 "
                      name="comment"
                      value={formData.comment}
                      onChange={onChange}

                    />
                    <div className="error-container-contact p-0 m-0">
                      {errors.comment && (
                        <span className="error-contact-form">{errors.comment}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                    <div className="row align-items-start">
                      <div className="col-lg-12 col-xl-6 col-md-6 col-sm-12 col-12">
                        <button
                          type="submit"
                          className="fill-btn outline-btn2 border-0 text-uppercase"
                          disabled={loading}
                          style={{ width: "80%" }}
                        >
                          {loading ? (
                            <Circles
                              visible={true}
                              height="30"
                              width="30"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass="d-flex justify-content-center"
                              color={"white"}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                      <div className="col-lg-12 col-xl-6 col-md-6 col-sm-12 col-12 mt-sm-3 mt d-flex justify-content-start flex-column flex-wrap">
                        <Recaptcha onChange={onCaptchaChange} />
                        <div className="error-container-contact p-0 m-0">
                          {errors.verify && (
                            <span className="error-contact-form">{errors.verify}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 " >
                <div className="col-inner-box2 d-flex flex-column justify-content-evenly " >
                  <div className="d-flex flex-row w-100 warning-box mb-5 gap-3 py-3 justify-content-center align-items-center">
                    <img
                      src={questionicon}
                      alt="icon"
                      className="object-fit-contain"
                    />
                    <p className="text-WH p-0 m-0 warning-textp">
                      The fastest way to reach us is to{" "}
                      <a href="https://wa.me/+919915578189">
                        <span className="fs -normal" style={{ color: '#00FF44' }}>send us message.</span>
                      </a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-008 d-flex align-items-center mb-3">
                      <img
                        style={{ paddingRight: "10px" }}
                        src={mailicon}
                        alt="icon"
                      />
                      Email
                    </span>
                    <p className="contact-details-tag fw-normal p-0 m-0 ">
                      <strong className="fw-medium">Business Inquiry:</strong>{" "}
                      <a className="contact-details" href="mailto:business@ditinustechnology.com">
                        business@ditinustechnology.com
                      </a>
                    </p>
                    <p className="contact-details-tag fw-normal p-0 m-0 ">
                      <strong className="fw-medium">HR Inquiry:</strong>{" "}
                      <a className="contact-details" href="mailto:hr@ditinustechnology.com">
                        hr@ditinustechnology.com
                      </a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-008 d-flex align-items-center mb-3 mt-2">
                      <img
                        src={callicon}
                        alt="icon"
                        style={{ paddingRight: "10px" }}
                      />
                      Phone
                    </span>
                    <p className="contact-details-tag fw-normal  p-0 m-0">
                      <strong className="fw-medium">Business Phone:</strong>{" "}
                      <a className="contact-details" href="callto:+919915578189">+91 991 557 8189</a>
                    </p>
                    <p className="contact-details-tag fw-normal p-0 m-0">
                      <strong className="fw-medium">HR Phone:</strong>{" "}
                      <a className="contact-details" href="callto:+916239382356">+91 623 938 2356</a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-008 d-flex align-items-center mb-3 mt-2">
                      <img
                        src={locationicon}
                        alt="icon"
                        style={{ paddingRight: "10px" }}
                      />
                      Office Address
                    </span>
                    <div className="d-flex flex-column gap-1">
                      {/* <p className="heading-008 fw-normal p-0 m-0">Mohali</p> */}
                      <strong className=" heading-008 fw-bold">
                        Ditinus Technology
                      </strong>{" "}
                      <p className="heading-008 fw-normal p-0 m-0">E:302, Vista Infotech Industrial Area,</p>
                      <p className="heading-008 fw-normal p-0 m-0"> Sector 75, Sahibzada Ajit Singh Nagar,</p>
                      <p className="heading-008 fw-normal p-0 m-0">Punjab 160055</p>
                      <p className="heading-008 fw-normal p-0 m-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="location-map">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13721.732367589504!2d76.67965970711168!3d30.7062228435495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef85bcf88ab3%3A0x520191891f8d816d!2sDitinus%20Technology%20Private%20Ltd.%20Mohali!5e0!3m2!1sen!2sin!4v1724237123562!5m2!1sen!2sin"
                  width="100%"
                  height={600}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
