import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { behance, upwork, logo } from "src/assets";

const Header = () => {
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBlogs, setIsBlogs] = useState<boolean>(false);
  const [clicked, setClicked] = useState(false)
  const [company, setCompany] = useState(false)
  const [service, setService] = useState(false)
  const [hire, setHire] = useState(false)
  const [outsource, setOutsource] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const fetchBlogs = async () => {
    const response = await api.get(`/blogs?page=1`);
    setIsBlogs(response?.data?.data?.length > 0);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const navTabs = document.querySelector(".navigation");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (navTabs) {
        if (currentScroll > 20) {
          navTabs.classList.add(toggleClass);
        } else {
          navTabs.classList.remove(toggleClass);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseOver = () => {
    setDropdownVisible(true);
  };

  const handleMouseOut = () => {
    setDropdownVisible(false);
  };
  return (
    <div>
      <header className="d-flex align-items-center justify-content-center w-100 header flex-column navigation  ">
        <nav className="navbar navbar-expand-lg container">
          <Link
            className="web-nav-logo-tab text-decoration-none text-denim fs-4"
            to="/"
          >
            <img loading="lazy" src={logo} alt="img" />
          </Link>
          <div className="web-nav-links w-100 align-items-center justify-content-center d-flex menu-header">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link">
                <Link
                  className={`nav-link ${location.pathname === "/" ? "active" : ""
                    }`}
                  to="/"
                >
                  Company<i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: 9 }}>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/about-us" ? "active" : ""
                        }`}
                      to="/about-us"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/training" ? "active" : ""
                        }`}
                      to="/training"
                    >
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/career" ? "active" : ""
                        }`}
                      to="/career"
                    >
                      Career
                    </Link>
                  </li>
                  {isBlogs && (
                    <li>
                      <Link
                        className={`nav-link ${location.pathname === "/blogs" ? "active" : ""
                          }`}
                        to="/blogs"
                      >
                        Blogs
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
              <li className="nav-item dropdown-link">
                <Link
                  className={`nav-link ${location.pathname === "/services" ? "active" : ""
                    }`}
                  to="/services"
                >
                  Services <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: 5 }}>
                  <li className="nav-item dropdown-link">
                    <Link
                      className={`nav-link ${location.pathname === "" ? "active" : ""
                        }`}
                      to="/ui-ux"
                    >
                      Design <i className="bi bi-chevron-down ms-1" />
                    </Link>
                    <ul className="sub-menu1" style={{ padding: 5 }}>
                      <li className="nav-item dropdown-link">
                        <Link
                          className={`nav-link ${location.pathname === "/ui-ux" ? "active" : ""
                            }`}
                          to="/ui-ux"
                        >
                          UI/UX
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`nav-link ${location.pathname === "/graphics" ? "active" : ""
                            }`}
                          to="/graphics"
                        >
                          Graphics
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/software-development"
                        ? "active"
                        : ""
                        }`}
                      to="/software-development"
                    >
                      Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${location.pathname === "/digital-marketing"
                        ? "active"
                        : ""
                        }`}
                      to="/digital-marketing"
                    >
                      Digital Marketing
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname === "/case-studies" ? "active" : ""
                    }`}
                  to="/case-studies"
                >
                  Case Studies
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname === "/contact-us" ? "active" : ""
                    }`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className=" web-nav-links  desktop-view d-flex flex-row ms-auto align-items-center">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link">
                <div
                  className="nav-link position-relative"
                  // to="/"
                  style={{
                    width: 115,
                    padding: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                >
                  Hire Us On<i className="bi bi-chevron-down ms-1" />
                </div>
                <ul
                  style={{
                    width: 117,
                    padding: 8,
                    gap: 4,
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 19,
                  }}
                >
                  <li>
                    <Link
                      className="nav-link"
                      target="_blank"
                      to="https://www.upwork.com/agencies/1293525604405526528/"
                    >
                      <img loading="lazy" src={upwork} alt="logo" style={{ width: 25 }} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      target="_blank"
                      to="https://www.behance.net/ditinus"
                    >
                      <img loading="lazy" src={behance} alt="logo" style={{ width: 25 }} />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className=" web-nav-links  navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link ">
                <Link
                  className="nav-link"
                  to="/business-process-services"
                  style={{
                    width: 170,
                    padding: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                >
                  Outsource With Us <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul className="menuDropDownNew" >
                  <li>
                    <Link className="nav-link text-white" to="/business-process-services">
                      Business Process Services
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <button
            onClick={toggleMenu}
            className="web-nav-toggle border-0 p-0 text-white toggleMenuBar "
          >
            <i className="bi bi-list display-3 d-flex align-items-center " />
          </button>
        </nav>
      </header>

      <div className={`bg-shadow-cover ${isMenuOpen ? "showShadow" : ""} `} />
      <div
        className={`mobile-nav-sidebar navbar-toggler ${isMenuOpen ? "showMenu" : ""
          }`}
      >
        <div className="cover-mobile-menu" >
          <div className="mobile-logo-header d-flex">
            <Link
              className="web-nav-logo text-decoration-nonepx-4 text-denim fs-4"
              to="/home"
            >
              <img loading="lazy" src={logo} alt="img" />
            </Link>
            <button
              type="button"
              onClick={toggleMenu}
              className="text-hover-gold ms-auto toggleBtnClose bg-transparent border-0"
            >
              <i className="bi bi-x-lg text-white fs-1" />
            </button>
          </div>
          <div className="mobile-menu-body py-0 flex-column d-flex">
            <ul className="navbar-nav mb-2 mb-lg-0 mobileNavigation">
              <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center text-white w-100">
                <li

                  className="nav-item dropdown-link">
                  <Link className="nav-link d-flex justify-content-between" to="/">
                    <a className=" col-6 text-white" href="/"
                      onClick={() => {
                        toggleMenu()
                      }}>
                      Company
                    </a> <i className=" bi bi-chevron-down ms-1" onClick={() => { setCompany(!company); setClicked(false) }} />
                  </Link>
                  <ul style={{ width: "300px !important", marginLeft: '150px' }} className={company ? "" : "d-none"}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/about-us"
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/training"
                      >
                        Training
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/career"
                      >
                        Career
                      </Link>
                    </li>
                    {isBlogs && (
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          to="/blogs"
                        >
                          Blogs
                        </Link>
                      </li>)}
                  </ul>
                </li>
                <li
                  className="nav-item dropdown-link text-white">
                  <Link
                    className="nav-link" to="/services">
                    <a className=" col-6 text-white" href="/"
                      onClick={() => {
                        toggleMenu()
                      }}>
                      Services
                    </a>
                    <i className="bi bi-chevron-down ms-1" onClick={() => { setService(!service); setClicked(false); }} />
                  </Link>
                  <ul style={{ width: "300px !important", padding: '10px', marginLeft: '150px' }} className={service ? "" : "d-none"}>
                    <li className="nav-item dropdown-link ">
                      <li
                        className={`nav-link ${location.pathname === "" ? "active" : ""
                          }`}
                        style={{ fontSize: '19px' }}
                        onClick={() => setClicked(!clicked)}
                      >
                        Design <i className="bi bi-chevron-down ms-1" />
                      </li>
                      <ul className={clicked ? 'dropdown-link ' : "d-none"} style={{ padding: '10px', marginBottom: "100px", marginLeft: "-180px" }}>
                        <li className="nav-item dropdown-link">
                          <Link
                            className={`nav-link ${location.pathname === "/ui-ux" ? "active" : ""
                              }`}
                            to="/ui-ux"
                            onClick={toggleMenu}
                          >
                            UI/UX
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`nav-link ${location.pathname === "/graphics" ? "active" : ""
                              }`}
                            to="/graphics"
                            onClick={toggleMenu}
                          >
                            Graphics
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/software-development"
                      >
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/digital-marketing"
                      >
                        Digital Marketing
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className="nav-item text-white"
                  onClick={() => {
                    setClicked(false);
                    toggleMenu();
                  }}
                >
                  <Link className="nav-link" to="/case-studies">
                    Case Studies
                  </Link>
                </li>
                <li
                  className="nav-item text-white"
                  onClick={() => {
                    setClicked(false);
                    toggleMenu();
                  }}
                >
                  <Link className="nav-link" to="/contact-us">
                    Contact
                  </Link>
                </li>

                <li
                  className="nav-item text-white"
                  onClick={() => setClicked(false)}
                >
                  <Link className="nav-link" to="#" >
                    Hire Us On<i className="bi bi-chevron-down ms-1"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      onClick={() => setHire(!hire)} />
                  </Link>

                  {isDropdownVisible && (
                    <div className={hire ? "d-flex dropdown-menu" : "d-none"} style={{ marginLeft: '150px' }}>
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          target="_blank"
                          to="/https://www.upwork.com/agencies/1293525604405526528/"
                        >
                          <img
                            src={upwork}
                            alt="Upwork logo"
                            style={{ width: 25 }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={toggleMenu}
                          className="nav-link"
                          target="_blank"
                          to="/https://www.behance.net/ditinus"
                        >
                          <img
                            src={behance}
                            alt="Behance logo"
                            style={{ width: 25 }}
                          />
                        </Link>
                      </li>
                    </div>
                  )}
                </li>

                <li
                  onDoubleClick={() => {
                    setClicked(false);
                    toggleMenu();
                  }}
                  className="nav-item dropdown-link text-white">
                  <Link className="nav-link" to="/business-process-services" >
                    <a className=" col-6 text-white" href="/"
                      onClick={() => {
                        toggleMenu()
                      }}>
                      Outsource With Us
                    </a>
                    <i className="bi bi-chevron-down ms-1" onClick={() => setOutsource(!outsource)} />
                  </Link>
                  <ul style={{ width: "290px", marginLeft: '20px', justifyContent: "center" }} className={outsource ? "" : "d-none"}>
                    <li>
                      <Link
                        onClick={toggleMenu}
                        className="nav-link"
                        to="/business-process-services"
                      >
                        Business Process Services
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Header;
