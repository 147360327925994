import "./index.css";
// import "./assets/css/slick.css";
import "./assets/css/bootstrap.css";
import "./assets/css/animation.css";
import "./assets/css/style.css";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs/index";
import Services from "./Pages/Services";
import Blogs from "./Pages/Blogs";
import ContactUs from "./Pages/ContactUs";
import SingleBlog from "./Pages/Blogs/SingleBlog";
import UIUX from "./Pages/UIUX";
import DigitalMaketing from "./Pages/DigitalMarketing";
import Development from "./Pages/Development";
import Training from "./Pages/Training";
import Graphics from "./Pages/Graphics";
import TermsConditions from "./Pages/TermsConditions";
import Career from "./Pages/Career";
import ApplyNow from "./Pages/Career/ApplyNow";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

import CaseStudy from "./Pages/CaseStudy/SingleCaseStudy";
import KpoBpo from "./Pages/KpoBpo";
import CaseStudys from "./Pages/CaseStudy";
import GetAQute from "./Components/GetAQoute";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import FloatingMedia from "./Components/FloatingMedia";
import axios from "axios";
import { useEffect, useState } from "react";
import AnimatedCursor from "react-animated-cursor"
import { ditinusMOV } from "./assets";
const App = () => {
  const [isBlogs, setIsBlogs] = useState<boolean>(false);

  const location = useLocation();

  const show =
    location.pathname !== "/contact-us" &&
    location.pathname !== "/training" &&
    location.pathname !== "/business-process-services" &&
    !location.pathname.includes("/career");

  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const fetchBlogs = async () => {
    const response = await api.get(`/blogs?page=1`);
    setIsBlogs(response?.data?.data?.length > 0);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={10}
        outerStyle={{
          border: 'solid 2px',
          borderColor: '#0D72B8',
          background: 'transparent',
          padding: '15px'
        }}
        color='255,255, 255'
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link',
          {
            target: '.custom',
            // options: {
            //   innerSize: 12,
            //   outerSize: 12,
            //   color: '#fffff',
            //   outerAlpha: 0.3,
            //   innerScale: 0.7,
            //   outerScale: 5
            // }
          }
        ]}
      /> */}

      <ScrollToTop />
      <FloatingMedia />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/ui-ux" element={<UIUX />} />
        <Route path="/digital-marketing" element={<DigitalMaketing />} />
        <Route path="/software-development" element={<Development />} />
        <Route path="/graphics" element={<Graphics />} />
        <Route path="/business-process-services" element={<KpoBpo />} />
        <Route path="/training" element={<Training />} />

        <Route path="/career/:id" element={<ApplyNow />} />
        <Route path="/career" element={<Career />} />
        <Route path="/case-study/:slug" element={<CaseStudy />} />
        <Route path="/case-studies" element={<CaseStudys />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        {isBlogs && <Route path="/blogs" element={<Blogs />} />}
        {isBlogs && <Route path="/blog/:slug" element={<SingleBlog />} />}
        <Route path="*" element={<Home />} />
      </Routes >
      {show && <GetAQute />
      }
      <Footer />
    </>
  );
};

export default App;
